import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'

const Button = ({children, typeStyle, angleright = false }) => (
    <StyledButton typeStyle={typeStyle}>
        {children} {angleright ? <FontAwesomeIcon icon={faAngleRight} /> : null}
    </StyledButton>
)

export default Button

const StyledButton = styled.button`

    display: inline-block;
    border-radius: 30px;
    position: relative;
    z-index: 1;
    padding: 8px 40px;

    .svg-inline--fa {
        font-size: 1.3rem;
        height: 1.3rem;
        margin-left 0.5rem;
        position: relative;
        top: 2px;
    }

    &:focus {
        outline: none;
    }

    color: ${ ({typeStyle}) => 
        typeStyle === 'outline-white' ? 'white'
        : typeStyle === 'filled-white' ? ({ theme }) => theme.primaryDark
        : typeStyle === 'filled-light' ? ({ theme }) => theme.primaryDark
        : 'white'
    };
    background: ${ ({typeStyle}) =>
        typeStyle === 'outline-white' ? 'transparent'
        : typeStyle === 'filled-white' ? 'white'
        : typeStyle === 'filled-light' ? ({ theme }) => theme.primaryLight
        : ({ theme }) => theme.primaryDark
    };
    border: ${ ({typeStyle}) =>
        typeStyle === 'outline-white' ? '1px solid white'
        : 'none'
    };

    &:hover {
        opacity: 0.9;
        transition: color 0.4s ease-in-out, background 0.4s ease-in-out;
        color: ${ ({typeStyle}) => 
            typeStyle === 'outline-white' ? ({ theme }) => theme.primaryDark
            : typeStyle === 'filled-white' ? ({ theme }) => theme.primaryDark
            : typeStyle === 'filled-light' ? ({ theme }) => theme.primaryDark
            : 'white'
        };
        background: ${ ({typeStyle}) =>
            typeStyle === 'outline-white' ? 'white'
            : typeStyle === 'filled-white' ? 'white'
            : typeStyle === 'filled-light' ? ({ theme }) => theme.primaryLight
            : ({ theme }) => theme.primaryDark
        };
    }

`;