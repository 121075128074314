import React from "react";
import styled from 'styled-components';

import Button from '../button';
import FadeInSection from "../common/FadeInSection/FadeInSection";

const ContactForm = ({ acf }) => {
   
    return (
        <Style>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <FadeInSection>
                            <h3>{acf.title}</h3>
                            <p>{acf.description}</p>
                        </FadeInSection>
                        <form name="Contact Form" method="POST" data-netlify="true" className="contact-form">
                            <input type="hidden" name="form-name" value="Contact Form" />
                            <FadeInSection>
                                <div className="row">
                                    <div className="col-12">
                                        <label>
                                            My name is
                                        </label>
                                        <input type="text" name="name" id="name" placeholder="Your Full Name" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <label>
                                            You can reach me at
                                        </label>
                                        <input type="email" name="email" id="email" placeholder="email@company.com" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <label>
                                            Which solution are you looking for?
                                        </label>
                                        <select
                                            name="solution"
                                            id="solution"
                                        >
                                            <option value="" disabled selected></option>
                                            <option value="webapp">Web app</option>
                                            <option value="mobileapp">Mobile app</option>
                                            <option value="consultancy">Consultancy</option>
                                            <option value="website">Website</option>
                                            <option value="ecommerce">E-commerce site</option>
                                            <option value="media">Media (design, video production, etc)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <label>
                                            Tell us about your project
                                        </label>
                                        <textarea name="message" id="message" rows="5" placeholder="Start typing here..." />
                                    </div>
                                </div>
                                <div className="contact-button">
                                    <Button
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </FadeInSection>
                        </form>
                    </div>
                </div>
            </div>
        </Style>
    );
}

export default ContactForm;

export const fragment = graphql`
    fragment ContactForm on WORDPRESS_AcfContactformBlock {
        acf {
            title
            description
        }
    }
`;

const Style = styled.div`
    .contact-form {
        max-width: 500px;
        margin: 0 auto;
    }
    background: ${({ theme }) => theme.primaryLight};
    padding-top: ${({ paddingTop }) => paddingTop ? paddingTop : '10%'};
    padding-bottom: ${({ paddingBottom }) => paddingBottom ? paddingBottom : '10%'};
    h3 {
        text-align: center;
        color: ${({ theme }) => theme.secondaryDark};
        margin-bottom: 3rem;
    }
    label {
        margin-bottom: 0px;
        font-weight: 500;
        color: ${({ theme }) => theme.secondaryDark};
    }
    input {
        width: 100%;
        margin-bottom: 2rem;
        color: ${({ theme }) => theme.primaryDark};
        background: transparent;
        border: none;
        border-bottom: ${({ theme }) => `2px solid ${theme.secondaryDark}`};
        &:hover {
            border: none;
            border-bottom: ${({ theme }) => `2px solid ${theme.secondaryDark}`};
        }
        &:focus {
            outline: none;
            border: none;
            border-bottom: ${({ theme }) => `2px solid ${theme.secondaryDark}`};
        }
    }
    textarea {
        width: 100%;
        height: 100px;
        color: ${({ theme }) => theme.primaryDark};
        margin-bottom: 1rem;
        border: none;
        background: transparent;
        border-bottom: ${({ theme }) => `2px solid ${theme.secondaryDark}`};
        &:hover {
            border: none;
            border-bottom: ${({ theme }) => `2px solid ${theme.secondaryDark}`};
        }
        &:focus {
            outline: none;
            border: none;
            border-bottom: ${({ theme }) => `2px solid ${theme.secondaryDark}`};
        }
    }
    select {
        color: ${({ theme }) => theme.primaryDark};
        width: 100%;
        display: block;
        border: none;
        background: transparent;
        border-bottom: ${({ theme }) => `2px solid ${theme.secondaryDark}`};
        margin-top: 0.5rem;
        margin-bottom: 2rem;
        &:focus {
            outline: none;
            border: none;
            border-bottom: ${({ theme }) => `2px solid ${theme.secondaryDark}`};
        }
    }
    .contact-button {
        margin-top: 1rem;
        text-align: center;
    }
    img {
        border-radius: 15px;
    }
`