import React, { useState } from 'react'
import { graphql } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import FadeInSection from "../../../common/FadeInSection/FadeInSection"

// Main Tab
const ProcessTabs = ({ data }) => {
    
    const [activeTab, setActiveTab] = useState('Discussing your needs');

    return (
        <TabsStyle>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <FadeInSection>
                            <h2>{data.title}</h2>
                            <p>{data.text}</p>
                        </FadeInSection>
                        <FadeInSection>
                            <TabButtons
                                activeTab = {activeTab}
                                setActiveTab = {setActiveTab}
                                tabs = {data.tabs}
                            />
                            <TabContent
                                activeTab = {activeTab}
                                tabs = {data.tabs}
                            />
                        </FadeInSection>
                    </div>
                </div>
            </div>
        </TabsStyle>
    )
}
export default ProcessTabs;

// Main Tab CSS 
const TabsStyle = styled.div`
    padding-top: 10%;
    @media only screen and (max-width: 767px) and (min-width: 576px) {
        margin-right: -30px;
        margin-left: -30px;
    }
`

// Tab Buttons
const TabButtons = ({ activeTab, setActiveTab, tabs }) => {
    return (
        <TabButtonStyle activeTab={activeTab} setActiveTab={setActiveTab}>
            <div className="row no-gutters">
                {tabs.map(tab => {
                    const icon = ['fal', tab.icon && tab.icon];
                    return (
                        <div className="col-12 col-sm">
                            <div 
                                onClick = { () => setActiveTab(tab.title) }
                                className = {`button-container card card-body h-100 justify-content-center ${activeTab === tab.title ? ' active' : ''} `}
                            >
                                <FontAwesomeIcon icon={icon} />
                                <p>{tab.title}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </TabButtonStyle>
    )
}

// Tab Buttons CSS 
const TabButtonStyle = styled.div`
    margin-top: 3rem;
    margin-bottom: 4rem;
    svg {
        font-size: 2rem;
        display: block;
        margin: 0 auto;
        margin-bottom: 1rem;
    }
    .button-container {
        border-radius: 6px;
        text-align: center;
        height: 150px;
        max-width: 150px !important;
        color: ${({ theme }) => theme.primaryDark};
        background: none;
        border: none;
        padding: 10px 20px;
        font-size: 0.8rem;
        font-weight: 500;
        &:hover {
            transition: background 0.4s ease-in-out;
            background: ${({ theme }) => theme.primaryDark};
            color: white;
            cursor: pointer;
        }
        &.active {
            transition: background 0.4s ease-in-out;
            background: ${({ theme }) => theme.primaryLight};
            color: ${({ theme }) => theme.primaryDark};
        }
        p {
            margin-bottom: 0px;
        }
        @media only screen and (max-width: 1200px) {
            font-size: 0.65rem;
        }
        @media only screen and (max-width: 991px) {
            padding: 10px 0px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 0.55rem;
        }
        @media only screen and (max-width: 767px) {
            max-width: 100% !important;
        }
    }
`

// Tab Content
const TabContent = ({ activeTab, tabs }) => {
    return (
        <TabContentStyle>
            {tabs.map(tab => {
                if (tab.title === activeTab) {
                    return (
                        <div className="row align-items-center fadein-transition">
                            <div className="col">
                                <h3>{tab.title}</h3>
                                <p>{tab.text}</p>
                            </div>
                        </div>
                    );
                }
            })}

        </TabContentStyle>
    )
}

// Tab Content CSS 
const TabContentStyle = styled.div`
    max-width: 700px;
    margin: 0 auto;
    h3 {
        color: ${({ theme }) => theme.primaryDark};
        margin-bottom: 0.5rem;
    }
    h4 {
        color: ${({ theme }) => theme.primaryDark};
        margin-bottom: 0.4rem;
    }
    p {
        font-size: 0.9rem;
    }
`