import React from 'react';
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import styled from 'styled-components';

import Hero from '../../blocks/hero';
import SolutionTabs from './components/SolutionTabs';
import ProcessTabs from './components/ProcessTabs';
import CasesWidget from '../../blocks/casesWidget.js';
import Call2Action from '../../blocks/call2Action.js';

import FadeInSection from "../../common/FadeInSection/FadeInSection"

const PageWebDevelopment = ({ acf }) => {

    return (
        <>
            <Hero acf={acf.main_banner} page="development" />

            <SolutionsStyled>
                <div className="container-fluid" id="development-solutions">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <FadeInSection>
                                    <h2>{acf.solutions.title}</h2>
                                    <p>{acf.solutions.text}</p>
                                </FadeInSection>
                            </div>
                        </div>
                        <SolutionTabs data={acf.solutions.solutions}/>
                    </div>
                </div>
            </SolutionsStyled>

            <ProcessTabs data={acf.tabs_process} />

            <ImageWithTextStyled>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 order-2 order-lg-1">
                            <div className="text-content">
                                <FadeInSection>
                                    {acf.image_with_text_2.title && <h2>{acf.image_with_text_2.title}</h2>}
                                    {acf.image_with_text_2.text && <div dangerouslySetInnerHTML={{ __html: acf.image_with_text_2.text && acf.image_with_text_2.text }} />}
                                </FadeInSection>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 order-1 order-lg-2">
                            {acf.image_with_text_2.image && <Img fluid={acf.image_with_text_2.image.imageFile.childImageSharp.fluid} />}
                        </div>
                    </div>
                </div>
            </ImageWithTextStyled>

            <ImageWithTextStyled>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6">
                            {acf.image_with_text.image && <Img fluid={acf.image_with_text.image.imageFile.childImageSharp.fluid} />}
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="text-content">
                                <FadeInSection>
                                    {acf.image_with_text.title && <h2>{acf.image_with_text.title}</h2>}
                                    {acf.image_with_text.text && <div dangerouslySetInnerHTML={{ __html: acf.image_with_text.text && acf.image_with_text.text }} />}
                                </FadeInSection>
                            </div>
                        </div>
                    </div>
                </div>
            </ImageWithTextStyled>

            <div className="pt-10">
                <CasesWidget acf={acf} />
            </div>
            
            <Call2Action acf={acf.call_2_action} />

        </>
    );
};

export default PageWebDevelopment;


const SolutionsStyled = styled.div`
    background: black;
    color: white;
    padding-top: 15%;
    padding-bottom: 15%;

    .solutions-box {
        padding: 25px;
    }

    svg {
        font-size: 3rem;
        margin-bottom: 1.5rem;    
    }
`

const ImageWithTextStyled = styled.div`
    padding-top: 10%;
    .gatsby-image-wrapper {
        height: 900px;
        border-radius: 15px;
        max-width: 700px;
        margin: 0 auto;
        @media only screen and (max-width: 991px) {
            height: 300px;
            margin-bottom: 3rem;
        }
    }
    .text-content {
        max-width: 650px;
        margin: 0 auto;
    }
    h3 {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        font-size: 1.3rem;
    }
    p {
        margin-bottom: 0.5rem;
    }
`

export const fragment = graphql`
    fragment PageWebDevelopment on WORDPRESS_AcfPagewebdevelopmentBlock {
        acf {
            main_banner {
                button_text
                button_url
                text
                title
                images {
                    image {
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid{
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                }
            }
            solutions {
                title
                text
                solutions {
                    text
                    title
                    icon
                }
            }
            image_with_text {
                text
                title
                image {
                    sourceUrl
                    altText
                    imageFile {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                }
            }
            image_with_text_2 {
                text
                title
                image {
                    sourceUrl
                    altText
                    imageFile {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                }
            }
            tabs_process {
                text
                title
                tabs {
                    icon
                    text
                    title
                }
            }
            cases_widget {
                id
                slug
                cases {
                    company
                    description
                    title
                    mainsolution
                }
                featuredImage {
                    altText
                    sourceUrl
                    imageFile {
                        childImageSharp {
                            fluid{
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                }
            }
            call_2_action {
                button_text
                button_url
                over_title
                title
            }
        }
    }
`;
