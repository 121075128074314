import React from 'react'
import { graphql } from "gatsby"
import styled from 'styled-components'

import { Link } from 'gatsby'
import Button from '../button.js'
import FadeInSection from "../common/FadeInSection/FadeInSection"

const Style = styled.div`
    .overtitle {
        margin-bottom: 1rem;
    }
`

const Call2Action = ({ acf }) => {
    return (
        <Style>
            <FadeInSection>
                <div className="container container-p text-center">
                    <div className="row">
                        <div className="col">
                            {acf.over_title && (
                                <div className="overtitle"><span>{acf.over_title}</span></div>
                            )}
                            <h2>{acf.title}</h2>
                            <Link to={`/${acf.button_url}`}>
                                <Button>{acf.button_text}</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </FadeInSection>
        </Style>
    )
}

export default Call2Action

export const fragment = graphql`
fragment Call2Action on WORDPRESS_AcfCall2actionBlock {
    acf {
        button_text
        button_url
        text
        title
        over_title
    }
}
`;