import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'

const TheLink = ({ children, to, size, color }) => {
    
    return (
        <Style size={size && size} color={color && color}>
            <Link to={to}>
                {children} <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 10 }} />
            </Link>
        </Style>
    )
}

export default TheLink;

const Style = styled.div`

    max-width: fit-content;

    a {
        font-size: ${ ({ size }) => size ? size : 'inherit' };
        color: ${ ({ theme, color }) => color === 'green' ? theme.primaryLight : theme.alternative };
        font-weight: 600;
        &:hover {
            cursor: pointer;
            text-decoration: none;
            &::after {
                content: "";
                display: block;
                height: 2px;
                background-color: ${ ({ theme, color }) => color === 'green' ? theme.primaryLight : theme.alternative };
                border-radius: 10px;
                animation: 0.5s ease 0s normal forwards 1 animationLink;
            }
        }
    }

    @keyframes animationLink {
        0% { width: 0% }
        100% { width: 100% }
    }
    
    @-webkit-keyframes animationLink {
        0% { width: 0% }
        100% { width: 100% }
    }

`
