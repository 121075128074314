import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';

import Img from 'gatsby-image';
import Button from '../button';
import BannerDevelopment from '../../images/svg/solutions-development.svg';
import BannerAgency from '../../images/svg/solutions-agency.svg';

const Hero = ({ acf, page }) => {
    return (
        <HeroStyled imagesQty={acf.images && acf.images.length}>
            <div className="container fadein-transition">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 hero-text">
                        {acf.title && <h1>{acf.title}</h1>}
                        {acf.text && <p>{acf.text}</p>}
                        {acf.button_text && 
                            <Link to={`${acf.button_url}`}>
                                <Button angleright>{acf.button_text}</Button>
                            </Link>
                        }
                    </div>
                    <div className="col-12 col-md-6 col-lg-8 hero-img">
                        {acf.images &&
                            acf.images.length > 1 ?
                                <HeroImgs>
                                    {acf.images && acf.images.map(image=>(
                                        <Img fluid={image.image.imageFile.childImageSharp.fluid} />
                                    ))}
                                </HeroImgs>
                            :
                                <div className="one-image">
                                    {acf.images && acf.images.map(image => (
                                        <>
                                            {image.image.sourceUrl.substr(image.image.sourceUrl.length - 3) === 'svg'
                                                ? <img className="image-svg" src={page === 'development' ? BannerDevelopment : BannerAgency} alt={image.image.altText} />
                                                :   <Img fluid={image.image.imageFile.childImageSharp.fluid} />
                                            }
                                        </>
                                    ))}
                                </div>
                        }
                    </div>
                </div>
            </div>
        </HeroStyled>
    )
}

export default Hero

export const fragment = graphql`
fragment Hero on WORDPRESS_AcfHeroBlock {
    acf {
        title
        text
        button_text
        button_url
        images {
            image {
                sourceUrl
                altText
                imageFile {
                    childImageSharp {
                        fluid{
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
        }
    }
}
`;


const HeroStyled = styled.div`

    img {
        border-radius: 15px;
    }

    p {
        font-size: 1.1rem;
    }

    .one-image {
        .gatsby-image-wrapper {
            height: 550px;
            @media only screen and (min-width: 768px) {
                width: 80%;
                left: 15%;
            }
            @media only screen and (max-width: 991px) {
                height: auto;
            }
            @media only screen and (max-width: 767px) {
                height: 300px;
                margin-bottom: 3rem;
            }
        }
        .image-svg {
            max-width: 500px;
            float: right;
            margin-top: 10%;
            @media only screen and (max-width: 991px) {
                max-width: -webkit-fill-available;
            }
            @media only screen and (max-width: 991px) {
                margin-top: 2rem;
                margin-bottom: 2rem;
                height: 300px;
                float: none;
                width: 100%;
            }
        }
    }

    @media only screen and (min-width: 992px) {
        height: 100vh;
        min-height: 800px;
        position: relative;
        margin-top: -90px;
        padding-top: 90px;
    }
    .hero-text {
        padding-top: 10%;
        @media only screen and (max-width: 991px) and (min-width: 768px) {
            padding-bottom: 10%;
        }
    }
    .hero-img {
        padding-top: 5%;
        @media only screen and (max-width: 991px) {
            padding-top: 25px;
        }
    }
    @media only screen and (min-width: 1500px) {
        min-height: 800px;
        .hero-text {padding-top: 12%;}
        .hero-img {padding-top: 10%;}
    }
    @media only screen and (max-width: 991px) and (min-width: 768px) {
        h1 {
            font-size: 1.5rem;
        }
    }

`

const HeroImgs = styled.div`

    position: relative;
    height: 450px;

    @media only screen and (max-width: 1199px) and (min-width: 992px) {
        top: 35px;
    }

    @media only screen and (max-width: 991px) {
        display: none;
    }

    div {
        height: auto;
        -webkit-animation: 1s ease 0s normal forwards 1 fadein;
        animation: 1s ease 0s normal forwards 1 fadein;
        &:first-child {
            max-width: 50%;
            top: -5%;
            left: 25%;
            z-index: 20;
            -webkit-animation: 1.5s ease 0s normal forwards 1 fadein;
            animation: 1.5s ease 0s normal forwards 1 fadein;
            @media only screen and (max-width: 991px) {
                top:0;
                left: 0;
                max-width: 60%;
            }
        }
        &:nth-child(2) {
            max-width: 40%;
            top: -45%;
            left: 60%;
            right: auto;
            z-index: 10;
            -webkit-animation: 0.5s ease 0s normal forwards 1 fadein;
            animation: 0.5s ease 0s normal forwards 1 fadein;
            @media only screen and (max-width: 991px) {
                top:25%;
                right: 0;
                left: auto;
                max-width: 60%;
                z-index: 20;
            }
        }
        &:nth-child(3) {
            max-width: 50%;
            top: -100%;
            left: 10%;
            z-index: 1;
            -webkit-animation: 2s ease 0s normal forwards 1 fadein;
            animation: 2s ease 0s normal forwards 1 fadein;
            @media only screen and (max-width: 991px) {
                top:50%;
                left: 0;
                max-width: 60%;
                z-index: 30;
            }
        }
        &:nth-child(4) {
            max-width: 50%;
            top: -130%;
            left: 40%;
            z-index: 5;
            -webkit-animation: 1.25s ease 0s normal forwards 1 fadein;
            animation: 1.25s ease 0s normal forwards 1 fadein;
            @media only screen and (max-width: 991px) {
                top:75%;
                right: 0;
                left: auto;
                max-width: 60%;
                z-index: 40;
            }
        }
    }

    @keyframes fadein{
        0% { opacity:0; transform:translate(25px, 50px); }
        100% { opacity:1; transform:translate(0px, 0px); }
    }
    
    @-webkit-keyframes fadein{
        0% { opacity:0; transform:translate(25px, 50px); }
        100% { opacity:1; transform:translate(0px, 0px); }
    }
`