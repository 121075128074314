import React from 'react'
import { graphql } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image"

import FadeInSection from "../common/FadeInSection/FadeInSection"
import TheLink from "../common/TheLink/TheLink"

const RowCols = ({ acf }) => {
    return (
        <ImgTxtStyle>
            <div className="row no-gutters align-items-center">
                <div className={`col-12 col-lg-6 col-image ${acf.column_order==='COLUMN_ORDER_1' ? 'order-1':'order-2'}`}>
                    <Img 
                        fluid={acf.image.imageFile.childImageSharp.fluid}
                        alt="acf.image.altText"
                    />
                </div>
                <div className={`col-12 col-lg-6 ${acf.column_order==='COLUMN_ORDER_1' ? 'order-2':'order-1'}`}>
                    <div className="col-text">
                        <FadeInSection>
                            {acf.title && <h2>{acf.title}</h2>}
                            {acf.text && <p>{acf.text}</p>}
                            {acf.button_text &&
                                <TheLink to={acf.button_url} size="1.1rem" color="green">
                                    {acf.button_text}
                                </TheLink>
                            }
                        </FadeInSection>
                    </div>
                </div>
            </div>
        </ImgTxtStyle>
    )
}

export default RowCols

export const fragment = graphql`
fragment RowCols on WORDPRESS_AcfRowcolsBlock {
    acf {
        title
        text
        column_order
        button_text
        button_url
        image {
            sourceUrl
            altText
            imageFile {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
    }
}
`;

const ImgTxtStyle = styled.div`
    background: ${({ theme }) => theme.secondaryDark};
    img {
        margin-bottom: 0px;
    }
    .col-image {
        .gatsby-image-wrapper {
            height: 700px;
            @media only screen and (max-width: 991px) {
                height: 300px;
            }
        }
    }
    .col-text {
        padding: 3rem;
        color: white;
        max-width: 90%;
        @media only screen and (max-width: 1200px) {
            max-width: 100%;
        }
        @media only screen and (max-width: 575px) {
            padding: 3rem 2rem;
        }
    }
    h2 {
        margin-bottom: 1.5rem;
    }
    p {
        font-size: 1.1rem;
    }
`