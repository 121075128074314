import React, { useState } from 'react'
import styled from 'styled-components'
import Img from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import FadeInSection from "../../../common/FadeInSection/FadeInSection"

// Main Tab
const SolutionTabs = ({ data }) => {
    
    const [activeTab, setActiveTab] = useState(data[0].title);

    return (
        <TabsStyle>
            <div className="row">
                <div className="col">
                    <FadeInSection>
                        <TabButtons
                            activeTab = {activeTab}
                            setActiveTab = {setActiveTab}
                            tabs = {data}
                        />
                        <TabContent
                            activeTab = {activeTab}
                            tabs = {data}
                        />
                    </FadeInSection>
                </div>
            </div>
        </TabsStyle>
    )
}
export default SolutionTabs

// Main Tab CSS 
const TabsStyle = styled.div`

`

// Tab Buttons
const TabButtons = ({ activeTab, setActiveTab, tabs }) => {
    return (
        <TabButtonStyle activeTab={activeTab} setActiveTab={setActiveTab}>
            <div className="row no-gutters">
                {tabs.map(tab => {
                    const icon = ['fal', tab.icon && tab.icon];
                    return (
                        <div className="col-12 col-sm">
                            <div 
                                onClick = { () => setActiveTab(tab.title) }
                                className = {`button-container card card-body h-100 justify-content-center ${activeTab === tab.title ? ' active' : ''} `}
                            >
                                <FontAwesomeIcon icon={icon} />
                                {tab.title}
                            </div>
                        </div>
                    )
                })}
            </div>
        </TabButtonStyle>
    )
}

// Tab Buttons CSS 
const TabButtonStyle = styled.div`
    margin-top: 2rem;
    margin-bottom: 4rem;
    .card {
        border-radius: 0px;
    }
    svg {
        font-size: 2rem;
        display: block;
        margin: 0 auto;
        margin-bottom: 1rem;
    }
    .button-container {
        background: none;
        text-align: center;
        color: white;
        padding: 20px 20px;
        font-size: 1.2rem;
        font-weight: 500;
        border-bottom: 1px solid white;
        &:hover {
            transition: background 0.4s ease-in-out;
            color: white;
            cursor: pointer;
        }
        &.active {
            transition: background 0.4s ease-in-out;
            color: ${({ theme }) => theme.primaryLight};
            border-bottom: 1px solid ${({ theme }) => theme.primaryLight};
        }
        p {
            margin-bottom: 0px;
        }
        @media only screen and (max-width: 991px) {
            font-size: 0.8rem;
            svg {
                font-size: 2rem;
            }
        }
    }
`

// Tab Content
const TabContent = ({ activeTab, tabs }) => {
    return (
        <TabContentStyle>
            {tabs.map(tab => {
                if (tab.title === activeTab) {
                    return (
                        <div className="row align-items-center fadein-transition">
                            <div className="col">
                                <h3>{tab.title}</h3>
                                {tab.text && <div dangerouslySetInnerHTML={{ __html: tab.text }} />}
                            </div>
                        </div>
                    );
                }
            })}

        </TabContentStyle>
    )
}

// Tab Content CSS 
const TabContentStyle = styled.div`
    max-width: 700px;
    margin: 0 auto;
    h3 {
        margin-bottom: 1rem;
    }
    h4 {
        color: ${({ theme }) => theme.primaryDark};
        margin-bottom: 0.4rem;
    }
    p {
        font-size: 1rem;
    }

    .ecommerce-icon {
        position: relative;
        bottom: 5px;
    }

`