import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"

import HeroHome from '../components/blocks/heroHome'
import Hero from '../components/blocks/hero'
import RowCols from '../components/blocks/rowCols'
import Tabs from '../components/blocks/tabs'
import CasesWidget from '../components/blocks/casesWidget'
import Call2Action from '../components/blocks/call2Action'
import IconList from '../components/blocks/iconList'
import ContactForm from '../components/blocks/contactForm'
import PageWebDevelopment from '../components/pages/solutions/webDevelopment'
import PageAgency from '../components/pages/solutions/agency'

import './style.css'

const PageTemplate = ({ data }) => {

    const blocks = data.wordpress.page.blocks;

    return (
        <Layout pageTitle={data.wordpress.page.title}>
            {blocks.map(block => {
                const typeName = block.__typename;
                switch (typeName) {
                case 'WORDPRESS_AcfHerohomeBlock':
                    return <HeroHome {...block} />
                case 'WORDPRESS_AcfHeroBlock':
                    return <Hero {...block} />
                case 'WORDPRESS_AcfRowcolsBlock':
                    return <RowCols {...block} />
                case 'WORDPRESS_AcfTabsBlock':
                    return <Tabs {...block} />
                case 'WORDPRESS_AcfCaseswidgetBlock':
                    return <CasesWidget {...block} />
                case 'WORDPRESS_AcfCall2actionBlock':
                    return <Call2Action {...block} />
                case 'WORDPRESS_AcfIconlistBlock':
                    return <IconList {...block} />
                case 'WORDPRESS_AcfContactformBlock':
                    return <ContactForm {...block} />
                case 'WORDPRESS_AcfPagewebdevelopmentBlock':
                    return <PageWebDevelopment {...block} />
                case 'WORDPRESS_AcfPageagencyBlock':
                    return <PageAgency {...block} />
                default:
                    return <p>Sorry, no sections to display.</p>
                }
            })}
        </Layout>
    )

}

export default PageTemplate

export const query = graphql`
query ($id: ID!) {
    wordpress {
        page(id: $id) {
            id
            title
            slug
            blocks {
                __typename
                ...HeroHome
                ...Hero
                ...RowCols
                ...Tabs
                ...CasesWidget
                ...Call2Action
                ...IconList
                ...ContactForm
                ...PageWebDevelopment
                ...PageAgency
            }
        }
    }
}
`