import React from 'react'
import { graphql } from "gatsby"
import styled from 'styled-components'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLinkedinIn} from '@fortawesome/free-brands-svg-icons'
import FadeInSection from "../common/FadeInSection/FadeInSection"

const IconList = ({ acf }) => {
    return (
        <Style
            background={acf.background && acf.background}
            paddingTop={acf.padding_top && acf.padding_top}
            paddingBottom={acf.padding_bottom && acf.padding_bottom}
            maxWidth={acf.max_width && acf.max_width}
        >
            {acf.display_type === 'Horizontal' &&
                <div id={acf.section_id && acf.section_id} className="container horizontal">
                    <div className="row">
                        <div className="col">
                            <FadeInSection>
                            {acf.title && <h2>{acf.title}</h2>}
                            </FadeInSection>
                            <div className="row">
                                {acf.icon_list && acf.icon_list.map(item => {
                                    const icon = ['fal', item.icon && item.icon];
                                    return (
                                        <div className={
                                            item.icon === 'facebook' || item.icon === 'instagram' || item.icon === 'linkedin'
                                            ? 'col'
                                            : 'col-12 col-sm-12 col-md-6 col-lg-4'
                                        }>
                                            <FadeInSection>
                                                <div className="icon-list">
                                                    {
                                                        item.icon === 'facebook' ?
                                                            <a href='https://www.facebook.com/iqplusconsulting/' rel="noopener noreferrer" target="_blank">
                                                                <FontAwesomeIcon icon={faFacebookF} />
                                                            </a>
                                                        : item.icon === 'instagram' ?
                                                            <a href='https://www.instagram.com/iqplusconsulting/' rel="noopener noreferrer" target="_blank">
                                                                <FontAwesomeIcon icon={faInstagram} />
                                                            </a>
                                                        : item.icon === 'linkedin' ?
                                                            <a href='https://www.linkedin.com/company/iqplusconsulting' rel="noopener noreferrer" target="_blank">
                                                                <FontAwesomeIcon icon={faLinkedinIn} />
                                                            </a>
                                                        :
                                                            <FontAwesomeIcon icon={icon} />
                                                    }
                                                    <h3>{item.title && item.title}</h3>
                                                    {item.title && item.title === 'Visit us' ?
                                                        (
                                                            <div className="row no-gutters">
                                                                <div className="col">
                                                                    <h4>Norway Office</h4>
                                                                    <p>Nydalsveien 15<br/>0484 Oslo</p>
                                                                </div>
                                                                <div className="col">
                                                                    <h4>Argentina Office</h4>
                                                                    <p>El Salvador 5218<br/>1414 Buenos Aires</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    :
                                                        (
                                                            <div dangerouslySetInnerHTML={{ __html: item.text && item.text }} />
                                                        )
                                                    }
                                                </div>
                                            </FadeInSection>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {acf.display_type === 'Vertical' &&
                <div
                    className={`container vertical ${acf.section_id && acf.section_id === '1' ? 'fadein-transition' : ''}`}
                    id={acf.section_id && acf.section_id}
                >
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12 col-md-6">
                            {acf.title && <h2>{acf.title}</h2>}
                            {acf.icon_list && acf.icon_list.map(item => {
                                const icon = ['fal', item.icon && item.icon];
                                return (
                                    <div className="icon-list">
                                        <FontAwesomeIcon icon={icon} />
                                        <h3>{item.title && item.title}</h3>
                                        {item.title && item.title === 'Visit us' ?
                                            (
                                                <div className="row no-gutters">
                                                    <div className="col">
                                                        <h4>Norway Office</h4>
                                                        <p>Nydalsveien 15<br/>0484 Oslo</p>
                                                    </div>
                                                    <div className="col">
                                                        <h4>Argentina Office</h4>
                                                        <p>El Salvador 5218<br/>1414 Buenos Aires</p>
                                                    </div>
                                                </div>
                                            )
                                        :
                                            (
                                                <div dangerouslySetInnerHTML={{ __html: item.text && item.text }} />
                                            )
                                        }
                                    </div>
                                );
                            })}
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                            <div className="vertical-image">
                                <Img fluid={acf.image_vertical.imageFile.childImageSharp.fluid} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Style>
    )
}

export default IconList

export const fragment = graphql`
    fragment IconList on WORDPRESS_AcfIconlistBlock {
        acf {
            title
            background
            display_type
            max_width
            icon_list {
                icon
                text
                title
            }
            image_vertical {
                sourceUrl
                imageFile {
                    childImageSharp {
                        fluid{
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
            padding_top
            padding_bottom
            section_id
        }
    }
`;

const Style = styled.div`
    background:
    ${({ background, theme }) =>
    background === 'secondaryDark' ? theme.secondaryDark
    : background === 'primaryDark' ? theme.primaryDark
    : 'white'};
    color: ${({ background }) => background === 'secondaryDark' || background === 'primaryDark' ? 'white' : 'black'};
    @media only screen and (min-width: 992px) {
        .row {
            max-width: ${({ maxWidth }) => maxWidth ? maxWidth : ''};
        }
    }
    .icon-list {
        margin-bottom: 3rem;
    }
    svg {
        font-size: 1.8rem;
        margin-bottom: 0.8rem;
    }
    h2 {
        margin-bottom: 3rem;
    }
    h3 {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
    }
    h4 {
        margin-bottom: 0.5rem;
    }
    p {
        font-size: 0.9rem;
    }     
    a {
        color: ${({ theme }) => theme.primaryDark};
    }
    img {
        border-radius: 15px;
    }
    .horizontal {
        p {
            max-width: 325px;
        }
        padding-top: ${({ paddingTop }) => paddingTop ? paddingTop : '10%'};
        padding-bottom: ${({ paddingBottom }) => paddingBottom ? paddingBottom : '10%'};
        @media only screen and (max-width: 767px) {
            p {
                max-width: 100%;
            }
        }
    }
    .vertical {
        padding-top: ${({ paddingTop }) => paddingTop ? paddingTop : ''};
        padding-bottom: ${({ paddingBottom }) => paddingBottom ? paddingBottom : ''};
        p {
            margin-bottom: 0px;
        }
        &-image {
            .gatsby-image-wrapper {
                height: 650px;
                @media only screen and (max-width: 767px) {
                    height: 300px;
                    margin-top: 3rem;
                }
            }
        }
        .icon-list:last-child {
            margin-bottom: 0px;
        }
    }
`