import React from 'react';
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import styled from 'styled-components';

import Hero from '../../blocks/hero';
import SolutionTabs from './components/SolutionTabs';
import CasesWidget from '../../blocks/casesWidget.js';
import Call2Action from '../../blocks/call2Action.js';

const PageAgency = ({ acf }) => {

    return (
        <>
            <Hero acf={acf.main_banner} page="agency" />

            <SolutionsStyled>
                <div className="container-fluid" id="agency-solutions">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                {acf.solutions.title && <h2>{acf.solutions.title}</h2>}
                                {acf.solutions.text && <p>{acf.solutions.text}</p>}
                            </div>
                        </div>
                        <SolutionTabs data={acf.solutions.solutions}/>
                    </div>
                </div>
            </SolutionsStyled>

            <div className="pt-10">
                <CasesWidget acf={acf} />
            </div>
            
            <Call2Action acf={acf.call_2_action} />

        </>
    );
};

export default PageAgency;


const SolutionsStyled = styled.div`
    background: black;
    color: white;
    padding-top: 15%;
    padding-bottom: 15%;

    .solutions-box {
        padding: 25px;
    }

    svg {
        font-size: 3rem;
        margin-bottom: 1.5rem;    
    }
`

export const fragment = graphql`
    fragment PageAgency on WORDPRESS_AcfPageagencyBlock {
        acf {
            main_banner {
                button_text
                button_url
                text
                title
                images {
                    image {
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid{
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                }
            }
            solutions {
                title
                text
                solutions {
                    text
                    title
                    icon
                }
            }
            cases_widget {
                id
                slug
                cases {
                    company
                    description
                    title
                    mainsolution
                }
                featuredImage {
                    altText
                    sourceUrl
                    imageFile {
                        childImageSharp {
                            fluid{
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                }
            }
            call_2_action {
                button_text
                button_url
                over_title
                title
            }
        }
    }
`;
