import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Img from "gatsby-image" 

import Button from '../button'
import HomeBanner from '../../images/svg/home-banner.svg';

const HeroHome = ({ acf }) => {

    const windowHeight = typeof window !== `undefined` && window.innerHeight;

    return (
        <HeroStyled windowHeight={windowHeight}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 col-lg-5 hero-text">
                        <h1>{acf.title}</h1>
                        <p>{acf.text}</p>
                        <Link to={acf.button_url}>
                            <Button angleright>{acf.button_text}</Button>
                        </Link>
                    </div>
                    {
                        acf.images.length > 1
                        ?
                            <div className="col-12 col-md-6 col-lg-7 hero-img">
                                <HeroImgs>
                                    {acf.images.map(image => (
                                        <Img fluid={image.imageFile.childImageSharp.fluid} alt={image.altText} />
                                    ))}
                                </HeroImgs>
                            </div>
                        :
                            <div className="col-12 col-md-6 col-lg-7 hero-svg">
                                {acf.images.map(image => (
                                    <img src={HomeBanner} alt={image.altText} />
                                ))}
                            </div>          
                    }
                </div>
            </div>
            <HeroLogos>
                <div className="container">
                    <p>{acf.logos_text}</p>
                    <div className="row align-items-center">
                        {acf.logos.map(logo => (
                            <>
                                <div className="col desktop-991">
                                    <Img fluid={logo.imageFile.childImageSharp.fluid} alt={logo.altText} />
                                </div>
                                <div className="col-6 col-sm-6 col-md-4 mobile-991">
                                    <Img fluid={logo.imageFile.childImageSharp.fluid} alt={logo.altText} />
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </HeroLogos>
        </HeroStyled>
    )
}

export default HeroHome

export const fragment = graphql`
fragment HeroHome on WORDPRESS_AcfHerohomeBlock {
    acf {
        title
        text
        button_text
        button_url
        logos_text
        images {
            sourceUrl
            imageFile {
                childImageSharp {
                    fluid{
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
        logos {
            sourceUrl
            imageFile {
                childImageSharp {
                    fluid{
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
    }
}
`;


const HeroStyled = styled.div`

    @media only screen and (min-width: 992px) {
        height: 100vh;
        min-height: 800px;
        position: relative;
        margin-top: -90px;
        padding-top: 90px;
    }
    .hero-text {
        p {
            font-size: 1.1rem;
        }
    }
    .hero-img {
        padding-top: 8%;
        @media only screen and (max-width: 991px) {
            padding-top: 25px;
        }
    }
    .hero-svg {
        text-align: right;
        img {
            max-width: 500px;
            height: 500px;
            @media only screen and (max-width: 991px) {
                max-width: 100%;
                height: auto;
            }
        }

    }
    @media only screen and (min-width: 1500px) {
        min-height: 800px;
        .hero-img {padding-top: 10%;}
    }
    @media only screen and (max-width: 991px) and (min-width: 768px) {
        h1 {
            font-size: 1.5rem;
        }
    }

`

const HeroImgs = styled.div`

    position: relative;
    height: 450px;

    @media only screen and (max-width: 1199px) and (min-width: 992px) {
        top: 35px;
    }

    img {
        border-radius: 15px;
    }

    div {
        height: auto;
        -webkit-animation: 1s ease 0s normal forwards 1 fadein;
        animation: 1s ease 0s normal forwards 1 fadein;
        &:first-child {
            max-width: 25%;
            top: 1%;
            left: 10%;
            z-index: 10;
            -webkit-animation: 1.5s ease 0s normal forwards 1 fadein;
            animation: 1.5s ease 0s normal forwards 1 fadein;
            @media only screen and (max-width: 991px) {
                top:0;
                left: 0;
                max-width: 60%;
            }
        }
        &:nth-child(2) {
            max-width: 30%;
            top: -30%;
            left: 30%;
            right: auto;
            -webkit-animation: 0.5s ease 0s normal forwards 1 fadein;
            animation: 0.5s ease 0s normal forwards 1 fadein;
            @media only screen and (max-width: 991px) {
                top:25%;
                right: 0;
                left: auto;
                max-width: 60%;
                z-index: 20;
            }
        }
        &:nth-child(3) {
            max-width: 20%;
            top: -45%;
            left: 65%;
            z-index: 10;
            -webkit-animation: 2s ease 0s normal forwards 1 fadein;
            animation: 2s ease 0s normal forwards 1 fadein;
            @media only screen and (max-width: 991px) {
                top:50%;
                left: 0;
                max-width: 60%;
                z-index: 30;
            }
        }
        &:nth-child(4) {
            max-width: 30%;
            top: -43%;
            left: 0;
            -webkit-animation: 1.25s ease 0s normal forwards 1 fadein;
            animation: 1.25s ease 0s normal forwards 1 fadein;
            @media only screen and (max-width: 991px) {
                top:75%;
                right: 0;
                left: auto;
                max-width: 60%;
                z-index: 40;
            }
        }
        &:nth-child(5) {
            max-width: 30%;
            top: -70%;
            left: 35%;
            -webkit-animation: 1s ease 0s normal forwards 1 fadein;
            animation: 1s ease 0s normal forwards 1 fadein;
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }        
        &:nth-child(6) {
            max-width: 30%;
            top: -120%;
            left: 70%;
            -webkit-animation: 0.75s ease 0s normal forwards 1 fadein;
            animation: 0.75s ease 0s normal forwards 1 fadein;
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
    }

    @keyframes fadein{
        0% { opacity:0; transform:translate(25px, 50px); }
        100% { opacity:1; transform:translate(0px, 0px); }
    }
    
    @-webkit-keyframes fadein{
        0% { opacity:0; transform:translate(25px, 50px); }
        100% { opacity:1; transform:translate(0px, 0px); }
    }
`

const HeroLogos = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #F6F6F6;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;

    .gatsby-image-wrapper {
        max-width: 125px;
        @media only screen and (max-width: 991px) {    
            margin: 0 auto;
        }
    }

    img {
        filter: grayscale(1);
        margin: 0;
    }

    p {
        font-style: italic;
        font-size: 0.8rem;
        @media only screen and (min-width: 992px) {    
            margin-bottom: 0px;
        }
    }

    @media only screen and (max-width: 991px) {
        position: relative;
        margin-top: 50px;
        padding-top: 75px;
        padding-bottom: 25px;
        .col-6, .col-sm-6 {
            margin-bottom: 25px;
        }
    }
`