import React, { useState } from 'react'
import { graphql, Link } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image"
import TheLink from '../common/TheLink/TheLink'
import FadeInSection from "../common/FadeInSection/FadeInSection"

import SolutionWebApp from '../../images/svg/home-solutions-webapp.svg';
import SolutionMobileApp from '../../images/svg/home-solutions-mobileapp.svg';
import SolutionAgency from '../../images/svg/home-solutions-agency.svg';
import SolutionConsultancy from '../../images/svg/home-solutions-consultancy.svg';

// Main Tab
const Tabs = ({ acf }) => {
    
    const [activeTab, setActiveTab] = useState('Web Apps');

    return (
        <TabsStyle
            paddingTop={acf.padding_top && acf.padding_top}
            paddingBottom={acf.padding_bottom && acf.padding_bottom}
        >
            <div className="container" id={acf.section_id && acf.section_id}>
                <div className="row">
                    <div className="col">
                        <FadeInSection>
                            <h2>{acf.section_main_title}</h2>
                        </FadeInSection>
                        <FadeInSection>
                        <TabButtons
                            activeTab = {activeTab}
                            setActiveTab = {setActiveTab}
                            tabs = {acf.tabs}
                        />
                        </FadeInSection>
                        <FadeInSection>
                        <TabContent
                            activeTab = {activeTab}
                            tabs = {acf.tabs}
                        />
                        </FadeInSection>
                    </div>
                </div>
            </div>
        </TabsStyle>
    )
}
export default Tabs

// Main Tab CSS 
const TabsStyle = styled.div`
    padding-top: ${({ paddingTop }) => paddingTop ? paddingTop : '10%'};
    padding-bottom: ${({ paddingBottom }) => paddingBottom ? paddingBottom : '10%'};
`

// Tab Buttons
const TabButtons = ({ activeTab, setActiveTab, tabs }) => {
    return (
        <TabButtonStyle activeTab={activeTab} setActiveTab={setActiveTab}>
            <ul>
                {tabs.map(tab => (
                    <li>
                        <button 
                            onClick = { () => setActiveTab(tab.tab_title) }
                            className = { activeTab === tab.tab_title ? 'active' : ''}
                        >
                                {tab.tab_title}
                        </button>
                    </li>
                ))}
            </ul>
        </TabButtonStyle>
    )
}

// Tab Buttons CSS 
const TabButtonStyle = styled.div`
    margin-top: 3rem;
    margin-bottom: 3rem;
    ul {
        margin-left: 0;
    }
    li {
        display: inline;
        margin-right: 25px;
        @media only screen and (max-width: 767px) {
            display: grid;
            margin-right: auto;
        }
    }
    button {
        color: ${({ theme }) => theme.primaryDark};
        background: none;
        border: none;
        padding: 10px 20px;
        border-radius: 30px;
        font-weight: 500;
        &:focus {
            outline: none;
        }
        &:hover {
            transition: background 0.4s ease-in-out;
            background: ${({ theme }) => theme.primaryDark};
            color: white;
        }
        &.active {
            transition: background 0.4s ease-in-out;
            background: ${({ theme }) => theme.primaryLight};
            color: ${({ theme }) => theme.primaryDark};
        }
    }
`

// Tab Content
const TabContent = ({ activeTab, tabs }) => {
    return (
        <TabContentStyle>
            {tabs.map(tab => {
                if (tab.tab_title === activeTab) {
                    return (
                        <div className="row align-items-center fadein-transition">
                            <div className="col-md-6">
                                <h3>{tab.tab_title}</h3>
                                <p>{tab.text}</p>
                                <ul className="ul-no-class">
                                    {tab.icon_content.map(icon => (
                                        <>
                                            <li>
                                                <h4>{icon.icon_title}</h4>
                                                <p>{icon.icon_text}</p>
                                            </li>
                                        </>
                                    ))}
                                </ul>
                                {tab.learn_more && (
                                    <TheLink to={tab.learn_more}>
                                        Learn More
                                    </TheLink>
                                )}
                            </div>
                            <div className="col-md-6">
                                <img
                                    alt={tab.image.altText}
                                    src={
                                        tab.tab_title === 'Web Apps' ? SolutionWebApp
                                        : tab.tab_title === 'Mobile Apps' ? SolutionMobileApp
                                        : tab.tab_title === 'Agency' ? SolutionAgency
                                        : tab.tab_title === 'Consultancy' ? SolutionConsultancy
                                        : null
                                    }
                                />
                            </div>
                        </div>
                    );
                }
            })}

        </TabContentStyle>
    )
}


// Tab Content CSS 
const TabContentStyle = styled.div`
    h3 {
        color: ${({ theme }) => theme.primaryDark};
    }
    h4 {
        color: ${({ theme }) => theme.primaryDark};
        margin-bottom: 0.4rem;
    }
`

// GraphQL
export const fragment = graphql`
fragment Tabs on WORDPRESS_AcfTabsBlock {
    acf {
        section_main_title
        section_id
        tabs {
            tab_title
            text
            title
            learn_more
            icon_content {
                icon
                icon_text
                icon_title
            }
            image {
                sourceUrl
                altText
                imageFile {
                    childImageSharp {
                        fluid{
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
        }
    }
}
`;