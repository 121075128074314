import React from 'react'
import styled from 'styled-components'

function FadeInSection (props) {
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
      return () => observer.unobserve(domRef.current);
    }, []);
    return (
        <Style>
            <div
                className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
                ref={domRef}
            >
                {props.children}
            </div>
        </Style>
    );
}
  
export default FadeInSection;

const Style = styled.div`

    .fade-in-section {
        @media only screen and (min-width: 768px) {
            opacity: 0;
            transform: translateY(200px);
            visibility: hidden;
            transition: opacity 0.6s ease-out, transform 0.6s ease-out;
            will-change: opacity, visibility;
        }
    }
    .fade-in-section.is-visible {
        @media only screen and (min-width: 768px) {
            opacity: 1;
            transform: none;
            visibility: visible;
        }
    }

`