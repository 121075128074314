import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Link } from 'gatsby'
import FadeInSection from "../common/FadeInSection/FadeInSection"

import Img from "gatsby-image"
import Button from '../button'

const CasesWidget = ({ acf }) => {
    return (
        <Styled>
            <div className="row no-gutters" id="projects">
                {acf.cases_widget.map(oneCase => (
                    <div className="col-12 col-sm-12 col-lg-6 col-wrapper">
                        <div className="col-image">
                            <Img 
                                fluid={oneCase.featuredImage.imageFile.childImageSharp.fluid}
                                alt="oneCase.featuredImage.altText"
                            />
                        </div>
                        <div className="col-text">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <FadeInSection>
                                            <span>Case Study | {oneCase.cases.mainsolution} | {oneCase.cases.company}</span>
                                            <h3>{oneCase.cases.title}</h3>
                                        </FadeInSection>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-button">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <Link to={`/cases/${oneCase.slug}`}>
                                            <Button angleright typeStyle="outline-white">Learn More</Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Styled>
    )
}

export default CasesWidget

export const fragment = graphql`
fragment CasesWidget on WORDPRESS_AcfCaseswidgetBlock {
    acf {
        cases_widget {
            id
            slug
            cases {
                company
                description
                title
                mainsolution
            }
            featuredImage {
                altText
                sourceUrl
                imageFile {
                    childImageSharp {
                        fluid{
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
        }
    }
}
`;

const Styled = styled.div`

.container {
    max-width: 100%;
}

img {
    margin-bottom: 0px;
    filter: contrast(150%) brightness(75%);
}

.col-wrapper {
    position: relative;
    height: 700px;
    @media only screen and (max-width: 991px) {
        height: 500px;
    }
    &:hover {
        img {
            filter: contrast(150%) brightness(50%);
        }
    }
}

.col-image {
    .gatsby-image-wrapper {
        height: 700px;
        @media only screen and (max-width: 991px) {
            height: 500px;
        }
    }
}

.col-text {
    position: absolute;
    top: 4rem;
    left: 2rem;
    @media only screen and (max-width: 991px) {
        left: 0rem;
    }
}

.col-button {
    position: absolute;
    bottom: 4rem;
    right: 2rem;
}

span {
    color: white;
}

h3 {
    font-size: 2rem;
    color: white;
    margin-top: 2rem;
    max-width: 500px;
}

`;